import JobComponent from '../components/JobComponent'
import JobListing from '../components/JobListing'

const CareersPage =()=>{
    return (
        <>
        <JobComponent/>
        <JobListing/>
        </>
    )
}
export default CareersPage;