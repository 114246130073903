import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { FaBars } from "react-icons/fa6";
import logo from '../logo.png'
import { useNavigate } from 'react-router-dom';

function Navbar() {
    const [isOpen, setIsOpen] = useState(false);

    const toggleNavbar = () => {
        setIsOpen(!isOpen); // Toggle the state between true and false
    };

    const navigate = useNavigate();
    return (
        <header>
            <div className="navbar-container">

                <nav>
                    <div className="logo">
                        <img src={logo} alt="Logo" onClick={() => navigate('/', { replace: true })} />
                    </div>
                    <ul className= {isOpen?"nav-link active" : 'nav-link'}>
                        <li><Link to="/" className='active'>Home</Link></li>
                        <li><Link to="/features">Features</Link></li>
                        <li><Link to="/team">Teams</Link></li>
                        <li><Link to="/resources">Resources</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                    </ul>
                    <div className="icon" onClick={toggleNavbar}>
                        <FaBars />
                    </div>
                </nav>

            </div>
        </header>
    );
}

export default Navbar;