import CoreTeam from "../components/CoreTeam";
import ExtendedTeam from "../components/ExtendedTeam";
import { Helmet } from "react-helmet";
const TeamsPage = () => {
    return (
        <>
            <Helmet>
                <title>Meet the Team Behind Lumis - SaaS Experts at Your Service</title>
                <meta name="description" content="Get to know the talented team behind Lumis. We are passionate about helping businesses grow with innovative SaaS solutions." />
                <meta name="keywords" content="Lumis team, SaaS experts, business software team, Lumis professionals" />
                <meta property="og:title" content="Meet the Team Behind Lumis" />
                <meta property="og:description" content="Lumis is powered by a dedicated team of SaaS professionals focused on delivering the best software solutions for businesses." />
                <meta property="og:url" content="https://www.lumissoftware.co.ke/teams" />
                <link rel="canonical" href="/teams" />
            </Helmet>
            <CoreTeam />
            <ExtendedTeam />
        </>
    )
}
export default TeamsPage;