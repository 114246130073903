import React from 'react';
import './CoreTeam.css';
import { ImLinkedin2 } from "react-icons/im";

const teamMembers = [
  {
    name: 'Edmund Marshall-Lovsey',
    role: 'Creative',
    image: 'https://plus.unsplash.com/premium_photo-1682141308411-29d7662447cb?q=80&w=2072&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "When Ed isn't enjoying a newly brewed tipple, he heads down into a book or rolling the dice at a medieval tavern.",
    linkedin: 'https://linkedin.com/in/edmund'
  },
  {
    name: 'Elsa Monteith',
    role: 'Communications',
    image:'https://images.unsplash.com/photo-1698891668251-fd6974673f82?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "Elsa loves spending time in her kitchen surrounded by books, good friends, and a trusty cup of tea.",
    linkedin: 'https://linkedin.com/in/elsa'
  },
  {
    name: 'Holly Jackson',
    role: 'Operations',
    image: 'https://images.unsplash.com/photo-1560250097-0b93528c311a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "Holly loves plant-based cooking, walking in nature, and evenings with friends playing games.",
    linkedin: 'https://linkedin.com/in/holly'
  },
  {
    name: 'Sara Taiyo',
    role: 'Co-founder',
    image: 'https://images.unsplash.com/photo-1573497490790-9053816a01d4?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    description: "Sara enjoys cold-water swimming and experimenting with seasonal harvests from the community garden.",
    linkedin: 'https://linkedin.com/in/sara'
  }
];

const CoreTeam = () => {
  return (
    <div className="core-team-section">
      <h5>Core Team</h5>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <div className="team-member" key={index}>
            <img src={member.image} alt={member.name} className="team-member-img" />
            <div className="team-member-info">
              <h3>{member.name}</h3>
              <div className="position-container">
                <p className="role">{member.role}</p>
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  {/* <i className="fab fa-linkedin"></i> */}
                  <ImLinkedin2 />
                </a>
              </div>
              <p>{member.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CoreTeam;
