import React from 'react';
import './BenefitsSectionCards.css'; // Include custom CSS here

const BenefitsSectionCards = () => {
  return (
    <section className="features-section">
      <div className="feature-container">
        <div className="feature-item">
          <h3 className="feature-title">Automation Benefits</h3>
          <p className="feature-description">
            Streamline your operations with cutting-edge automation tools. Our platform helps you minimize manual tasks, freeing up time to focus on scaling your business.
          </p>
          <ul className="feature-list">
            <li>Real-time data processing</li>
            <li>Automated workflows and triggers</li>
            <li>Smart task management</li>
            <li>Customizable rule sets</li>
          </ul>
        </div>

        <div className="feature-item">
          <h3 className="feature-title">Collaboration Benefits</h3>
          <p className="feature-description">
            Foster seamless collaboration across teams. From project tracking to document sharing, our tools keep everyone aligned and ensure project success.
          </p>
          <ul className="feature-list">
            <li>Team-wide notifications</li>
            <li>Shared task boards</li>
            <li>Version control for documents</li>
            <li>Instant feedback loops</li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSectionCards;
