import React from 'react';
import './Hero.css'; // Importing external CSS for styles
import Lottie from 'react-lottie';
import animationData from '../coding-animation.json'
import { Typewriter } from 'react-simple-typewriter'
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };
  const navigate = useNavigate();

  return (
    <section className="hero-container">
      <div className="hero-content">
        {/* Left Side Content */}
        <div className="hero-text">
          <h1 className="hero-title">
            Scalable Full Stack Solutions Without the
            <span style={{ color: 'green', fontWeight: 'bold', marginLeft: '20px', marginRight: '20px' }}>
              {/* Style will be inherited from the parent element */}
              <Typewriter
                loop
                cursor
                typeSpeed={100}
                deleteSpeed={50}
                delaySpeed={2000}
                words={['Cortisol', 'Burnout', 'Overwhelm', 'Fatigue', 'Pressure', 'Anxiety', 'Tension']}
              />
            </span>
          </h1>
          <p className="hero-description">
            Lumis offers innovative SaaS solutions tailored to help businesses automate processes, enhance collaboration, and scale with ease. Our platform empowers teams to boost productivity, reduce operational complexity, and drive growth — all while providing cutting-edge tools that adapt to your needs. Whether you're a startup or an enterprise, Lumis is designed to help you succeed with efficiency and confidence.

          </p>
          <div className="hero-buttons">
            {/* <button className="hero-btn demo-btn">Check Testimonials</button> */}
            <button className="hero-btn explore-btn" onClick={() => navigate('/features')}>Explore Solutions</button>
          </div>
        </div>
        {/* Right Side Content */}
        <div className="hero-image-container">
          <Lottie
            options={defaultOptions}
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
