import React from 'react';
import './FeatureSection.css'; // External CSS for styling

const features = [
    {
        id: 6,
        title: "Mobile Development",
        description: "Build, deploy, and manage mobile applications effortlessly with Lumis' mobile development platform. Whether you're creating iOS or Android apps, our tools enable seamless development, testing, and optimization. From intuitive design interfaces to powerful backend integration, Lumis simplifies the entire mobile app lifecycle, helping you deliver exceptional user experiences on the go."
,
        image: "https://getconflux.com/static/communicate-375375abf42555b3ea565f66e205674a.svg", // Replace with actual image path
        alt: "Advanced Analytics"
    },

    {
        id: 1,
        title: "Web Development",
        description: "Build and scale web solutions effortlessly with Lumis. Our platform offers customizable templates, responsive designs, and robust backend support to ensure seamless experiences across all devices.",
        image: "https://buffer.com/static/google-business-profile/png/Screenshot.png",
        alt: "Public Ideaboard"
    },
    {
        id: 2,
        title: "System Development",
        description: "Streamline your system development with Lumis. We provide comprehensive tools for building efficient, scalable, and secure systems, tailored to meet your business needs.",
        image: "https://getconflux.com/static/6a3734c0c157383e956f21aaa6e535d1/ec873/emotion-analysis.png",
        alt: "Emotion Analysis"
    },

    {
        id: 5,
        title: "Graphic & Web Design",
        description: "Create stunning visuals and user-friendly web designs with Lumis. Our platform provides intuitive tools for designing responsive, aesthetically pleasing websites tailored to your brand.",
        image: "https://getconflux.com/static/collect-feedback-c49c008fefe44e0327de3eadd706bc67.svg", // Replace with actual image path
        alt: "Workflow Automation"
    },
    // {
    //     id: 3,
    //     title: "Automated Reporting",
    //     description: "Generate detailed reports automatically from customer data and KPIs. Gain insights on key business metrics and streamline decision-making with pre-scheduled reports delivered directly to your inbox.",
    //     image: "https://getconflux.com/static/a8ad35e12e64647317334c38fface92f/ec873/filters.png", // Replace with actual image path
    //     alt: "Automated Reporting"
    // },

];


const FeatureSection = () => {
    return (
        <div className="features-container">
            {features.map((feature, index) => (
                <div
                    className={`feature ${index % 2 === 0 ? 'image-left' : 'image-right'}`}
                    key={feature.id}
                >
                    <div className="feature-image">
                        <img src={feature.image} alt={feature.alt} />
                    </div>
                    <div className="feature-text">
                        <h3>{feature.title}</h3>
                        <p>{feature.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FeatureSection;
