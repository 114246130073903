import React from 'react';
import './BenefitsSection.css'; // You can define custom styles here

const BenefitsSection = () => {
  return (
    <section className="benefits-section">
      <div className="content-container">
        <h4 className="section-label">Platform Benefits</h4>
        <h2 className="main-heading">
          Empower your business with solutions that drive growth.
        </h2>
        <p className="subtext">
          Our all-in-one SaaS platform provides the tools you need to streamline operations, improve productivity, and accelerate your business growth. From automation to advanced analytics, we've got you covered.
        </p>
        <a href="#learn-more" className="cta-link">Discover more about our platform</a>
      </div>
      <img src="https://prod.gusto-assets.com/wp-content/uploads/Employee_benefits%402x-2048x780.png" alt="SaaS Platform Benefits" />
    </section>
  );
};

export default BenefitsSection;
