import Speech from "../components/Speech";
import FeatureText from "../components/FeatureText";
import FeatureSection from "../components/FeatureSection";
import BenefitsSection from "../components/BenefitsSection";
import BenefitsSectionCards from "../components/BenefitsSectionCards";
import { Helmet } from "react-helmet";
const FeaturesPage = () => {
    return (
        <>
            <Helmet>
                <title>Features of Lumis - Empowering Business Growth with Advanced Tools</title>
                <meta name="description" content="Explore the powerful features of Lumis that help businesses scale efficiently. From CRM to analytics, Lumis has it all." />
                <meta name="keywords" content="Lumis features, SaaS tools, CRM, business analytics, automation, small business software" />
                <meta property="og:title" content="Lumis Features - Advanced Tools for Business Growth" />
                <meta property="og:description" content="Lumis offers a wide range of features designed to boost your business growth. Learn more about our CRM, analytics, and automation tools." />
                <meta property="og:url" content="https://www.lumissoftware.co.ke/features" />
                <link rel="canonical" href="/features" />
            </Helmet>
            <Speech />
            <FeatureText></FeatureText>
            <FeatureSection />
            <BenefitsSection />
            <BenefitsSectionCards />
        </>
    );
}
export default FeaturesPage;