import React from 'react';
import './ContactForm.css'; // Importing the external CSS file
import Swal from 'sweetalert2'
import Lottie from 'react-lottie';
import animationData from '../contactanimation.json'; 
const ContactForm = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("access_key", "873eb0ef-a700-47a1-830e-bddee09c4647");

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        const res = await fetch("https://api.web3forms.com/submit", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json"
            },
            body: json
        }).then((res) => res.json());

        if (res.success) {
            Swal.fire({
                title: "Sucess!",
                text: "Message sent successfully",
                icon: "success"
            });
        }
    };

    return (
        <section className="contact-section">
            <div className="contact-container">
                {/* Left Side Image */}
                <div className="contact-image">
                    {/* You can use an actual image, or SVG here */}
                    {/* <img src={contactImage} alt="Contact Illustration" /> */}
                    <Lottie options={defaultOptions} height={250} width={250} />
                </div>

                {/* Right Side Form */}
                <div className="contact-form">
                    <h2 className="form-title">
                        <span>Get In</span> Touch
                    </h2>
                    <form onSubmit={onSubmit}>
                        <div className="form-group">
                            <input type="text" placeholder="Name" className="form-control" required name="Username" />
                        </div>
                        <div className="form-group">
                            <input type="email" placeholder="Email" className="form-control" required name='Email' />
                        </div>
                        <div className="form-group">
                            <textarea placeholder="Message" className="form-control" rows="5" name='Message' required></textarea>
                        </div>
                        <button type="submit" className="submit-btn">Submit</button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactForm;
