import React, { useState } from "react";
import "./JobListing.css";
import { useNavigate } from 'react-router-dom';

const jobData = [
  {
    id: 1,
    title: "Web Developer",
    location: "Remote",
    salary: "Ksh.40,000 - Ksh.50,000 per month",
    experience: "Minimum 1 year of professional web design experience.",
    skills: "Proficiency in any of web development frameworks or javascript libraries, strong understanding of UI/UX design principles, responsive design expertise.",
    description: "As a Web Developer at Lumis, you will be responsible for creating visually stunning and user-friendly websites. You will turn creative concepts into functional web interfaces that deliver exceptional user experiences.",
    applicationDeadline: "25th October 2024",
    responsibilities: [
      "Design and create visually appealing website layouts, graphics, and icons.",
      "Collaborate with UI/UX designers and other developers for seamless design integration.",
      "Conduct user research for optimized design decisions.",
      "Stay updated with industry trends and emerging design and development tools and techniques.",
      "Present design concepts to internal teams and clients for feedback and approval."
    ]
  },
  
  // Add more job objects here...
];

function JobListing() {
  const [expandedJobId, setExpandedJobId] = useState(null);

  const toggleExpand = (jobId) => {
    setExpandedJobId(expandedJobId === jobId ? null : jobId); // Toggle expansion
  };
  const navigate = useNavigate();

  return (
    <div className="job-listing">
      <h1 style={
      {fontFamily: "Merriweather"}
      } > <span style={{color:"white"}}>Job Listings at </span>Lumis</h1>
      <p className="intro-text">
        Explore our current job listings to discover exciting career opportunities that match your skill set and interests.
      </p>

      {/* Map through the job data and create a job card for each */}
      {jobData.map((job) => (
        <div key={job.id} className="job-card">
          <div className="job-overview">
            <div className="job-title">
              <h3>{job.title}</h3>
              <p>{job.location}</p>
            </div>
            <div className="apply-btn">
              <button onClick={() => navigate('/jobapplication')}>Apply Now ➔</button>
            </div>
          </div>

          <div className="job-details">
            <p><strong>Salary:</strong> {job.salary}</p>
            <p><strong>Experience:</strong> {job.experience}</p>
            <p><strong>Skills:</strong> {job.skills}</p>
          </div>

          {/* Expandable sections based on state */}
          {expandedJobId === job.id && (
            <div className="job-description">
              <h4>Job Description</h4>
              <p>{job.description}</p>
              <p><strong>Application Deadline:</strong> {job.applicationDeadline}</p>

              <h4>Responsibilities</h4>
              <ul>
                {job.responsibilities.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          )}

          {/* Show More / Show Less button */}
          <div className="toggle-btn">
            <button onClick={() => toggleExpand(job.id)}>
              {expandedJobId === job.id ? "Show Less ▲ " : "Show More ▼"}
            </button>
          </div>
        </div>
      ))}
    </div>
  );
}

export default JobListing;
