import React from "react";
import "./MetricsSection.css";
const MetricsSection = () => {
    return (
        <div className="metrics-section">
            {/* Left Side Content */}
            <div className="metrics-text">
                <h5 className="section-subtitle">Valuable Metrics</h5>
                <h2 className="section-title">Get actionable insights to help grow your business</h2>
                <p className="section-description">
                    Our platform provides deep insights into your user base, performance metrics, and helps you make data-driven decisions that will maximize your revenue and improve customer satisfaction.
                </p>

                {/* Stats Section */}
                <div className="metrics-stats">
                    <div className="metric">
                        <h3 className="merriweather-bold">8K+</h3>
                        <p><strong>Companies</strong> currently using our platform to scale and succeed.</p>
                    </div>
                    <div className="metric">
                        <h3 className="merriweather-bold">25K+</h3>
                        <p><strong>Countries</strong> with businesses benefiting from our services.</p>
                    </div>
                    <div className="metric">
                        <h3 className="merriweather-bold">98%</h3>
                        <p><strong>Customer satisfaction</strong> across all our services and offerings.</p>
                    </div>
                    <div className="metric">
                        <h3 className="merriweather-bold">12M+</h3>
                        <p><strong>Issues resolved</strong> by our intelligent support system.</p>
                    </div>
                </div>
            </div>

            {/* Right Side Image */}
            <div className="metrics-image">
                <div className="image-overlay">
                </div>
            </div>
        </div>
    );
};

export default MetricsSection;
