import React from 'react';
import './CloudInfrastructureSection.css';
import imageUrl from '../hero.png'

const CloudInfrastructureSection = () => {
  return (
    <div className="infrastructure-section">
      <div className="text-and-image-container">
        <div className="text-content">
          <h1 className='merriweather-bold'>Public Cloud Infrastructure. Modern Platform Endorphins.</h1>
          <p>
            The most flexible and powerful compute platform on any public cloud.
            Fly Machines are hardware-virtualized containers, running on our own
            hardware, that launch instantly and run exactly as long as you want
            them to — for a single HTTP request, or for weeks of uptime.
          </p>
        </div>
        <div className="image-container">
          <img
            src={imageUrl}
            alt="Globe with balloons"
            className="globe-image"
          />
        </div>
      </div>

      <div className="services-list">
        <div className="service-card">
          <div className="icon">🌍</div>
          <h3 className='merriweather-bold'> Get Right in Your Users’ Faces</h3>
          <p>Deploy in 35 regions, from Sydney to São Paulo, for sub-100ms response times and native-app feel no matter where your users are.</p>
        </div>
        <div className="service-card">
          <div className="icon">🔄</div>
          <h3 className='merriweather-bold'>Fork Off VMs Like They’re Processes</h3>
          <p>Fly Machines start fast enough to handle HTTP requests, run only when you need them, and scale into tens of thousands of instances.</p>
        </div>
        <div className="service-card">
          <div className="icon">⚡</div>
          <h3 className='merriweather-bold'> Ship GPU-Boosted Models</h3>
          <p>From LLMs to inferencing, hardware acceleration with the same developer experience as a simple CRUD app.</p>
        </div>
        <div className="service-card">
          <div className="icon">🔗</div>
          <h3 className='merriweather-bold'>Built for Distributed Systems</h3>
          <p>Clustered databases like Cockroach, globally-distributed Postgres, and modern RPC systems like Elixir FLAME, no Terraform required.</p>
        </div>
      </div>
    </div>
  );
};

export default CloudInfrastructureSection;
