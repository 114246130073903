import React from 'react';
import { useNavigate } from 'react-router-dom';
import './NotFoundPage.css'; // Importing the external CSS file

const NotFoundPage = () => {
  const navigate = useNavigate();

  return (
    <div className="notfound-container">
      <div className="notfound-content">
        <div className="notfound-image">
          {/* You can replace the img src with an actual image or use an SVG */}
          <img src="https://cdn.pixabay.com/photo/2021/07/21/12/49/error-6482984_960_720.png" alt="Ghost" className="ghost-image" />
        </div>
        <div className="notfound-text">
          <h1>Oops!</h1>
          <p>{"This page is under construction, kindly come back later :)"}</p>
          <button onClick={() => navigate(-1)} className="back-btn">Go back</button>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
