import React from 'react';
import './ExtendedTeam.css';
import { MdSunny } from "react-icons/md";
import { FaUserTie } from "react-icons/fa6";
import { FaSpa } from "react-icons/fa";

const teamMembers = [
    {
        name: 'Jon Barmby',
        role: 'Development',
        icon: <MdSunny size={'30px'} color='yellow' />,
        description: `As an avid film-and-photo-making enthusiast, Jon isn’t always behind the camera 
                  and often found putting pen to paper in writing film scripts. A vegetarian at heart, 
                  Jon is no stranger to a midweek kitchen experiment with a particular love for an 
                  abundance of garlic, and an evening in front of the footie.`,
        linkedin: 'https://linkedin.com/in/jon'
    },
    {
        name: 'John Foster',
        role: 'Development',
        icon: <MdSunny size={'30px'} color='yellow' />,
        description: `A self-proclaimed music nerd, when John’s not trawling for new tunes, he’s making it 
                  under the name ‘Hyperlife’. When not building prototypes at SpinUp, he can be found 
                  working on his friend’s websites or walking his adorable border collie.`,
        linkedin: 'https://linkedin.com/in/john'
    },
    {
        name: 'Piers Cowburn',
        role: 'Advisory Board',
        icon: <FaUserTie size={'30px'} color='green' />,
        description: `Driven by all things visual, Modyfi co-founder and creative engineer Piers shows 
                  enthusiastic interest in what can come from authentic collaboration, connectivity, 
                  community, and the power of AI.`,
        linkedin: 'https://linkedin.com/in/piers'
    },
    {
        name: 'Papa Akuffo',
        role: 'Advisory Board',
        icon: <FaUserTie size={'30px'} color='green' />,
        description: `As the London Design Director of a global design company, Papa adds his perspective 
                  and passion for making people’s lives better through design and strategy, 
                  bringing diverse and expert perspectives into Driftime’s thinking.`,
        linkedin: 'https://linkedin.com/in/papa'
    },
    {
        name: 'John Omeyo',
        role: 'Development',
        icon: <MdSunny size={'30px'} color='yellow' />,
        description: `When John's not tinkering with the latest technologies at SpinUp, you’ll likely find 
                  him down the gym or bouldering, trying to offset the 8 hours sitting at his desk 
                  perma-snacking. With a toddler in the midst, Martin relies on waking himself up 
                  with metal music through a past life as the lead gamer playing PUBG Mobile.`,
        linkedin: 'https://linkedin.com/in/martin'
    },
    {
        name: 'Ashleigh Penrod',
        role: 'Advisory Board',
        icon: <FaUserTie size={'30px'} color='green' />,
        description: `Founder of Mess Hill, Ashley has an interest in regenerative soil and cooperative communities.
                  Through her practice, Ashley makes regenerative and resilient practices the norm. Helping to 
                  run and build NOSTOS, a network of best-in-class independent agencies, Ashley has extensive 
                  experience in fostering shared resiliency and impact.`,
        linkedin: 'https://linkedin.com/in/ashleigh'
    },
    {
        name: 'Phil Clarke',
        role: 'Advisory Board',
        icon: <FaUserTie size={'30px'} color='green' />,
        description: `Fellow B Corp member, B Leader and founder of Consciam, Phil has more than 25 years of 
                  experience delivering complex projects, motivated by his environmental studies and 
                  interest in sustainability. Phil plays a fundamental role in advising Driftime on 
                  sustainable practices and core B Corp values.`,
        linkedin: 'https://linkedin.com/in/phil'
    },
    {
        name: 'Holly Jackson',
        role: 'Design',
        icon: <FaSpa size={'30px'} color='lightBlue' />,
        description: `Known as the team’s resident garden guru and home-grown vegetable aficionado, 
                  Holly loves plant-based cooking, walking in nature, and evenings spent with 
                  friends playing games.`,
        linkedin: 'https://linkedin.com/in/holly'
    }
];


const ExtendedTeam = () => {
    return (
        <div className="extended-team-section">
            <h5>Extended Team</h5>
            <div className="team-container">
                {teamMembers.map((member, index) => (
                    <div className="team-member" key={index}>
                        {member.icon}
                        <h3>{member.name}</h3>
                        <div className="position-container">
                            <p className="role">{member.role}</p>
                            <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                                <i className="fab fa-linkedin"></i> {/* LinkedIn Icon */}
                            </a>
                        </div>
                        <p className="description">{member.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ExtendedTeam;
