import React from "react";
import './SnapshotSection.css';

const SnapshotSection = () => {
  return (
    <div className="snapshot-section">
      <div className="left-content">
        <h2 className="heading">Welcome, here’s a little snapshot about us...</h2>
      </div>

      <div className="right-content">
        <div className="snapshot-card yellow">
          <h3 className="merriweather-bold">Trusted by Leading SaaS Innovators</h3>
          <div className="spacer"></div>
          <p>
            Our platform powers over 500+ SaaS companies to accelerate their digital transformation and streamline business operations with advanced automation and analytics.
          </p>
        </div>

        <div className="snapshot-card off-white">
          <h3 className="merriweather-bold ">Seamless Integration Across Platforms</h3>
          <div className="spacer"></div>
          <p>
            With our API-driven architecture, integrating your applications and services into the cloud ecosystem is easier than ever. Maximize efficiency and minimize complexity.
          </p>
        </div>

        <div className="snapshot-card beige">
          <h3 className="merriweather-bold ">Revenue Growth of Over £100M+</h3>
          <div className="spacer"></div>
          <p>
            Our clients have reported over £100M+ in revenue growth by leveraging our tailored SaaS solutions, built to scale with their evolving business needs.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SnapshotSection;
