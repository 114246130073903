import React from 'react';

const FeatureText = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>
        Lumis Softwares features
        <span style={styles.underline}></span>
      </h1>
      <p style={styles.subheading}>
        It's all about making your business goals a reality.
      </p>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    margin: '40px 0'
  },
  heading: {
    position: 'relative',
    fontWeight: 'bold',
    fontSize: '2.5rem',
    display: 'inline-block',
    marginBottom: '10px',
  },
  underline: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '0px', // Adjust this width based on the underline size you want
    height: '5px', // Adjust this height based on the underline thickness
    backgroundColor: 'green', // Yellow color
    borderRadius: '2px',
    zIndex: -1,
    transform: 'translateY(10px)',
  },
  subheading: {
    fontSize: '1.2rem',
    color: 'grey',
    marginTop:'20px'
  },
};

export default FeatureText;
