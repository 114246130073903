import Hero from "../components/Hero";
import MetricsSection from "../components/MetricsSection";
import SnapshotSection from "../components/SnapshotSection";
import Testimonials from "../components/Testimonial";
import { Helmet } from "react-helmet";
const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Lumis - The All-in-One SaaS Solution for Your Business</title>
                <meta name="description" content="Lumis is the all-in-one SaaS solution to help businesses scale efficiently. Discover our innovative features tailored for small businesses." />
                <meta name="keywords" content="Lumis, SaaS, business software, business growth, small business tools" />
                <meta property="og:title" content="Lumis - The All-in-One SaaS Solution for Your Business" />
                <meta property="og:description" content="Lumis empowers small businesses with essential tools for success. Explore our platform for streamlined operations and business growth." />
                <meta property="og:url" content="https://www.lumissoftware.co.ke" />
                <link rel="canonical" href="/" />
            </Helmet>
            <Hero />
            <SnapshotSection />
            {/* <CloudInfrastructureSection/> */}
            <MetricsSection />
            <Testimonials />
            {/* <Reviews/> */}
        </>
    );
}
export default HomePage;