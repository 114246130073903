import React from 'react';
import './Speech.css'; // You can define the CSS below in this file

const Speech = () => {
    return (
        <div className="testimonial-container">
            {/* Left column with the image */}
            <div className="testimonial-image">
                <img
                    src="https://images.unsplash.com/photo-1587837073080-448bc6a2329b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" // Replace with the correct path for your image
                    alt="Jakub Jaszczak"
                    className="testimonial-avatar"
                />
            </div>
            {/* Right column with the text */}
            <div className="testimonial-content">
                <img src="https://clubrespect.org.au/wp-content/uploads/2018/10/quotation-marks-white.png" alt="quote icon" className="quote-icon" />
                <br />
                <p className="testimonial-text">
                    Our platform is the backbone of our clients' success.
                    It consistently demonstrates how efficient and scalable our solutions are for businesses of all sizes.
                    We ensure continuous updates and improvements, driven by insights from our users and the expertise of our dedicated team.

                </p>
                <img src="https://clubrespect.org.au/wp-content/uploads/2018/10/quotation-marks-white.2.png" alt="quote icon" className="quote-icon" />
                <br />
                <h3 className="testimonial-name">Jakub Jaszczak</h3>
                <p className="testimonial-title">
                    Manager and CEO at Lumis Softwares
                </p>
            </div>
        </div>
    );
};

export default Speech;
