import React from 'react';
import './Footer.css'; // Add custom CSS for styling if needed
import { FaLinkedin, FaTwitter, FaFacebook } from 'react-icons/fa';
import logo from '../logo.png'
const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Company Info Section */}
                <div className="footer-section">
                    <img src={logo} alt="Company Logo" className="footer-logo" />
                    <p className="footer-description">
                        Empowering small businesses with an innovative, easy-to-use platform designed to help you grow.
                    </p>
                </div>

                {/* Quick Links Section */}
                <div className="footer-section">
                    <h3>Company</h3>
                    <ul>
                        <li><a href="/about">About Us</a></li>
                        <li><a href="/careers">Careers</a></li>
                        <li><a href="/partners">Partners</a></li>
                        <li><a href="/blog">Blog</a></li>
                        <li><a href="/contact">Contact Us</a></li>
                    </ul>
                </div>

                {/* Services Section */}
                <div className="footer-section">
                    <h3>Services</h3>
                    <ul>
                        <li><a href="/features">Features</a></li>
                        <li><a href="/pricing">Pricing</a></li>
                        <li><a href="/integrations">Integrations</a></li>
                        <li><a href="/api-docs">API Docs</a></li>
                        <li><a href="/support">Support</a></li>
                    </ul>
                </div>

                {/* Resources Section */}
                <div className="footer-section">
                    <h3>Resources</h3>
                    <ul>
                        <li><a href="/help">Help Center</a></li>
                        <li><a href="/how-it-works">How It Works</a></li>
                        <li><a href="/sign-in">Customer Sign In</a></li>
                        <li><a href="/privacy">Privacy Policy</a></li>
                        <li><a href="/terms">Terms of Service</a></li>
                    </ul>
                </div>
            </div>

            {/* Social Media Links */}
            <div className="footer-social">
                <p>Follow Us</p>
                <a href="https://linkedin.com" aria-label="LinkedIn"><FaLinkedin size={20} /></a>
                <a href="https://twitter.com" aria-label="Twitter"><FaTwitter size={20} /></a>
                <a href="https://facebook.com" aria-label="Facebook"><FaFacebook size={20} /></a>
            </div>
        </footer>
    );
};

export default Footer;
