import React from 'react';
import './Multinational.css';

const MultinationalSection = () => {
  return (
    <div className="multinational-section">
      <div className="content">
        <h1>Multinational, multi-lingual teams with unrivalled experience</h1>
        <p>
          We have 14 offices and experience of over 120 jurisdictions. Our multi-lingual teams can work in 30 languages.
          Our senior staff have the necessary expertise to advise your business how best to present the economic arguments
          that matter most for your case.
        </p>
        <button className="contact-btn">Contact Us</button>
      </div>
    </div>
  );
};

export default MultinationalSection;
