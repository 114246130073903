import React from 'react';
import './Testimonials.css'; // Import the CSS file

const testimonialsData = [
  {
    name: 'Alex Morgan',
    title: 'Startup Founder',
    testimonial: 'Lumis has completely transformed our business operations. The automation tools are fantastic!',
    img: 'https://images.unsplash.com/photo-1558222218-b7b54eede3f3?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' // Placeholder image
  },
  {
    name: 'Jane Foster',
    title: 'Marketing Lead',
    testimonial: 'Thanks to Lumis, our customer engagement has skyrocketed. Their platform is intuitive and reliable.',
    img: 'https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' // Placeholder image
  },
  {
    name: 'Mark Johnson',
    title: 'IT Specialist',
    testimonial: 'With Lumis, we’ve streamlined processes and improved collaboration. The support team is exceptional.',
    img: 'https://images.unsplash.com/photo-1537511446984-935f663eb1f4?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' // Placeholder image
  },
  {
    name: 'Sara Lee',
    title: 'E-commerce Manager',
    testimonial: 'I’ve saved so much time using Lumis to manage inventory and sales. It’s a game-changer for our e-commerce platform!',
    img: 'https://images.unsplash.com/photo-1573497491765-dccce02b29df?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' // Placeholder image
  },
  {
    name: 'David Patel',
    title: 'Operations Director',
    testimonial: 'Lumis gives us the flexibility to scale. It’s the best investment we’ve made for our company’s growth.',
    img: 'https://images.unsplash.com/photo-1585846416120-3a7354ed7d39?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' // Placeholder image
  },
  {
    name: 'Emily Wang',
    title: 'Product Manager',
    testimonial: 'The insights and analytics from Lumis have helped us optimize our product development process. Highly recommend!',
    img: 'https://images.unsplash.com/photo-1573496358961-3c82861ab8f4?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D' // Placeholder image
  }
];

const Testimonials = () => {
  return (
    <section className="testimonials-section">
      <div className="testimonials-header">
        <h1>Real People. <br />Real Results with Lumis.</h1>
        <p>Join thousands of businesses who trust Lumis to optimize, scale, and succeed with our powerful SaaS solutions.</p>
      </div>

      <div className="testimonials-grid">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="testimonial-card">
            <div className="testimonial-header">
              <img src={testimonial.img} alt={testimonial.name} className="testimonial-img" />
              <div>
                <h3>{testimonial.name}</h3>
                <h4>{testimonial.title}</h4>
              </div>
            </div>
            <p className="testimonial-text">"{testimonial.testimonial}"</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Testimonials;
