import CloudInfrastructureSection from "../components/CloundInfrastructureSection";
import { Helmet } from "react-helmet";

const ResourcesPage = () => {
    return (
        <>
      <Helmet>
        <title>Resources - Get the Most Out of Lumis with Expert Guides and Articles</title>
        <meta name="description" content="Access Lumis resources, including expert guides, tutorials, and articles to help you get the most out of our SaaS platform." />
        <meta name="keywords" content="Lumis resources, SaaS tutorials, business guides, expert articles, Lumis platform" />
        <meta property="og:title" content="Lumis Resources - Expert Guides and Articles" />
        <meta property="og:description" content="Explore Lumis resources for valuable insights, tutorials, and articles to maximize your business efficiency with our SaaS tools." />
        <meta property="og:url" content="https://www.lumissoftware.co.ke/resources" />
        <link rel="canonical" href="/resources" />
      </Helmet>
            <CloudInfrastructureSection />
        </>
    );
}
export default ResourcesPage;