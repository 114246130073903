import ContactForm from "../components/ContactForm";
import MultinationalSection from "../components/MultinationalSection";
import { Helmet } from "react-helmet";
const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact Lumis - Get in Touch with Us Today</title>
                <meta name="description" content="Have questions about Lumis? Contact our team for support, business inquiries, or partnership opportunities." />
                <meta name="keywords" content="Lumis contact, SaaS support, Lumis inquiries, business contact" />
                <meta property="og:title" content="Contact Lumis - Get in Touch" />
                <meta property="og:description" content="Reach out to Lumis for any business inquiries, support, or partnership opportunities. We're here to help." />
                <meta property="og:url" content="https://www.lumissoftware.co.ke/contact" />
                <link rel="canonical" href="/contact" />
            </Helmet>
            <MultinationalSection />
            <ContactForm />
            <br />
            <br />
            <br />
        </>
    );
}
export default ContactPage;